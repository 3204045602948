import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Markdown from 'markdown-to-jsx';
import { ReactNode, useEffect, useRef, useState } from 'react';

import Layout from '../components/Layout/Layout';
import useImageQuery from '../utils/use-image-query';
import styles from './brand-guidelines.module.scss';

import srcCopy from '../assets/brand/copy.svg';
import srcDownload from '../assets/brand/download.svg';
import srcBad from '../assets/brand/guidelines/bad.svg';
import srcGood from '../assets/brand/guidelines/good.svg';

import srcLogotypeHorizontalColorDark from '../assets/brand/logotype-horizontal-color-dark.svg';
import srcLogotypeHorizontalColorLight from '../assets/brand/logotype-horizontal-color-light.svg';
import srcLogotypeHorizontalMonoLineDark from '../assets/brand/logotype-horizontal-mono-line-dark.svg';
import srcLogotypeHorizontalMonoLineLight from '../assets/brand/logotype-horizontal-mono-line-light.svg';
import srcLogotypeHorizontalMonoSolidDark from '../assets/brand/logotype-horizontal-mono-solid-dark.svg';
import srcLogotypeHorizontalMonoSolidLight from '../assets/brand/logotype-horizontal-mono-solid-light.svg';
import srcLogotypeVerticalColorDark from '../assets/brand/logotype-vertical-color-dark.svg';
import srcLogotypeVerticalColorLight from '../assets/brand/logotype-vertical-color-light.svg';
import srcLogotypeVerticalMonoLineDark from '../assets/brand/logotype-vertical-mono-line-dark.svg';
import srcLogotypeVerticalMonoLineLight from '../assets/brand/logotype-vertical-mono-line-light.svg';
import srcLogotypeVerticalMonoSolidDark from '../assets/brand/logotype-vertical-mono-solid-dark.svg';
import srcLogotypeVerticalMonoSolidLight from '../assets/brand/logotype-vertical-mono-solid-light.svg';

import srcSymbolColor from '../assets/brand/symbol-color.svg';
import srcSymbolMonoLineDark from '../assets/brand/symbol-mono-line-dark.svg';
import srcSymbolMonoLineLight from '../assets/brand/symbol-mono-line-light.svg';
import srcSymbolMonoSolidDark from '../assets/brand/symbol-mono-solid-dark.svg';
import srcSymbolMonoSolidLight from '../assets/brand/symbol-mono-solid-light.svg';
import { StripType } from '../types';

interface Props {
  data: {
    contentful: {
      stripCollection: { items: StripType[] };
    };
  };
}

const COPIED_LABEL_DELAY = 3000;

export const brandGuidelinesPageQuery = graphql`
  query BrandGuidelinePageQuery($language: String!, $dev: Boolean!) {
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const onCopyDataFactory = (data: string) => () => {
  if (data) {
    copy(data);
  }
};

const CopyButton = ({
  data,
  children,
  className,
  containertClassName,
}: {
  data: string;
  children: ReactNode;
  className?: string;
  containertClassName?: string;
}) => {
  const [copied, setCopied] = useState(false);
  const copiedTimerRef = useRef<NodeJS.Timer | null>(null);
  const onCopy = onCopyDataFactory(data);

  return (
    <div className={cx(styles.copyButtonContainer, containertClassName)}>
      <button
        type="button"
        className={cx(className)}
        data-copy={data}
        onClick={() => {
          onCopy();
          setCopied(true);

          if (copiedTimerRef.current) {
            clearTimeout(copiedTimerRef.current);
            copiedTimerRef.current = null;
          }

          copiedTimerRef.current = setTimeout(() => {
            setCopied(false);
            copiedTimerRef.current = null;
          }, COPIED_LABEL_DELAY);
        }}
      >
        {children}
        <img src={srcCopy} alt="" width={24} />
      </button>
      {copied && <span className={styles.copiedLabel}>Copied</span>}
    </div>
  );
};

const BrandGuidelinesPage = ({ data }: Props) => {
  const { t, originalPath, i18n } = useI18next();
  const {
    srcBrandClearSpace,
    srcBrandClearSpaceVertical,
    srcBrandClearSpaceSymbol,
    srcBrandPartnerCollaboration,
    srcBrandMinimumDigitalLogotype,
    srcBrandMinimumDigitalSymbol,
    srcBrandMinimumPrintLogotype,
    srcBrandMinimumPrintSymbol,
    srcBrandMinimumVideoLogotype,
    srcBrandMinimumVideoSymbol,
    srcBrandMisuseDontBreakSpace,
    srcBrandMisuseDontCutBackground,
    srcBrandMisuseDontDecorate,
    srcBrandMisuseDontImageBackground,
    srcBrandMisuseDontImageColor,
    srcBrandMisuseDontImageContrast,
    srcBrandMisuseDontImageDistort,
    srcBrandMisuseDontImageHidden,
    srcBrandMisuseDontImageMirror,
    srcBrandMisuseDonImageOutline,
    srcBrandMisuseDontImageRotate,
    srcBrandMisuseDontImageShadow,
    srcBrandMisuseDontRearrange,
    srcBrandMisuseDontUseInText,
    srcBrandMisuseDontOutlineEffect,
    srcBrandAppScreenshotPayments,
    srcBrandAppScreenshotOrderSummary,
    srcBrandAppScreenshotHome,
    srcBrandAppScreenshotKycIntro,
    srcBrandAppScreenshotKycPremium,
    srcBrandAppScreenshotKyc,
    srcBrandAppScreenshotPaymentDetail,
    srcBrandAppScreenshotPaymentMethods,
    srcBrandBannerLargeApp,
    srcBrandBannerLargeHowSmartpayWorks,
    srcBrandBannerLargeKyc,
    srcBrandBannerLargeWhatIsSmartpay,
    srcBrandBannerSquareApp,
    srcBrandBannerSquareHowSmartpayWorks,
    srcBrandBannerSquareKyc,
    srcBrandBannerSquareWhatIsSmartpay,
    srcBrandVideoHowToUseBankDirect,
    srcBrandVideoHowToUseSmartpay,
  } = useImageQuery();
  const {
    contentful: {
      stripCollection: { items: strips },
    },
  } = data;

  useEffect(() => {
    const handler = (event: Event) => {
      if (
        event.target &&
        (event.target as HTMLImageElement).nodeName.toLowerCase() === 'img'
      ) {
        event.preventDefault();

        return false;
      }
    };

    document.addEventListener('dragstart', handler, false);

    return () => {
      document.removeEventListener('dragstart', handler, false);
    };
  }, []);

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      rootClassName={styles['root-wrapper']}
      strips={strips}
    >
      <div id="brand-guideline" className={cx(styles.container)}>
        <div className={cx(styles.box)}>
          <div className={cx(styles.content)}>
            <div>
              <h1>{t('brand-title')}</h1>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-description')}
              </Markdown>
              <h2 id="toc">{t('brand-toc')}</h2>
              <nav>
                <section>
                  <b>{t('brand-toc-brand')}</b>
                  <ul>
                    <li>
                      <a href="#text">{t('brand-toc-text')}</a>
                    </li>
                    <li>
                      <a href="#logo">{t('brand-toc-logo')}</a>
                    </li>
                    <li>
                      <a href="#spacing">{t('brand-toc-spacing')}</a>
                    </li>
                    <li>
                      <a href="#color-palette">
                        {t('brand-toc-color-palette')}
                      </a>
                    </li>
                    <li>
                      <a href="#minimum-size">{t('brand-toc-minimum-size')}</a>
                    </li>
                    <li>
                      <a href="#collaboration">
                        {t('brand-toc-collaboration')}
                      </a>
                    </li>
                    <li>
                      <a href="#misuse">{t('brand-toc-misuse')}</a>
                    </li>
                  </ul>
                </section>
                <section>
                  <b>{t('brand-toc-assets')}</b>
                  <ul>
                    <li>
                      <a href="#app-screenshots">
                        {t('brand-toc-app-screenshots')}
                      </a>
                    </li>
                    <li>
                      <a href="#banners">{t('brand-toc-banners')}</a>
                    </li>
                    <li>
                      <a href="#videos">{t('brand-toc-videos')}</a>
                    </li>
                  </ul>
                </section>
              </nav>
            </div>
            <hr />
            <section>
              <h2 id="text">{t('brand-toc-text')}</h2>
              <div className={styles.textBlock}>
                <div>
                  <Markdown options={{ forceBlock: true }}>
                    {t('brand-sec-text-desc')}
                  </Markdown>
                </div>
                <div>
                  <span className={styles.goodText}>
                    <img src={srcGood} alt="" width={48} />
                    <span>
                      <b>Smartpay</b>
                    </span>
                  </span>
                  <span className={styles.badText}>
                    <img src={srcBad} alt="" width={48} />
                    <span>
                      <b>smartpay</b>
                      <b>Smart Pay</b>
                      <b>SmartPay</b>
                    </span>
                  </span>
                </div>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="logo">{t('brand-toc-logo')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-logo-desc')}
              </Markdown>
              <h3>{t('brand-sec-logo-logotype-title')}</h3>
              <Markdown options={{ forceBlock: true }} className={styles.desc}>
                {t('brand-sec-logo-logotype-desc')}
              </Markdown>
              <figure className={styles.logotypeFigure}>
                <figcaption>
                  {t('brand-sec-logo-logotype-full-color')}
                </figcaption>
                <div className={cx(styles.flexFlow, styles.boxOutline)}>
                  <div className={cx(styles.flex1, styles.logotypeBox)}>
                    <img
                      src={srcLogotypeHorizontalColorDark}
                      height={77}
                      alt=""
                    />
                  </div>
                  <div
                    className={cx(
                      styles.darkBlock,
                      styles.flex1,
                      styles.logotypeBox
                    )}
                  >
                    <img
                      src={srcLogotypeHorizontalColorLight}
                      height={77}
                      alt=""
                    />
                  </div>
                </div>
              </figure>
              <figure className={styles.logotypeFigure}>
                <figcaption>
                  {t('brand-sec-logo-logotype-alternatives')}
                </figcaption>
                <div className={cx(styles.boxOutline)}>
                  <div className={cx(styles.flexFlow, styles.logotypeFlow)}>
                    <div className={cx(styles.flex1, styles.logotypeBox)}>
                      <img
                        src={srcLogotypeHorizontalMonoLineDark}
                        height={77}
                        alt=""
                      />
                    </div>
                    <div
                      className={cx(
                        styles.darkBlock,
                        styles.flex1,
                        styles.logotypeBox
                      )}
                    >
                      <img
                        src={srcLogotypeHorizontalMonoLineLight}
                        height={77}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={cx(styles.flexFlow, styles.logotypeFlow)}>
                    <div className={cx(styles.flex1, styles.logotypeBox)}>
                      <img
                        src={srcLogotypeHorizontalMonoSolidDark}
                        height={77}
                        alt=""
                      />
                    </div>
                    <div
                      className={cx(
                        styles.darkBlock,
                        styles.flex1,
                        styles.logotypeBox
                      )}
                    >
                      <img
                        src={srcLogotypeHorizontalMonoSolidLight}
                        height={77}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </figure>

              <h3>{t('brand-sec-logo-vertical-title')}</h3>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-logo-vertical-desc')}
              </Markdown>
              <div className={cx(styles.flexFlow, styles.verticalLogotypeFlow)}>
                <figure
                  className={cx(
                    styles.flex1,
                    styles.boxOutline,
                    styles.verticalLogotypeBox
                  )}
                >
                  <div
                    className={cx(
                      styles.flex1,
                      styles.verticalLogotypeBoxInner
                    )}
                  >
                    <img
                      src={srcLogotypeVerticalColorDark}
                      height={144}
                      alt=""
                    />
                  </div>
                  <div
                    className={cx(
                      styles.darkBlock,
                      styles.flex1,
                      styles.verticalLogotypeBoxInner
                    )}
                  >
                    <img
                      src={srcLogotypeVerticalColorLight}
                      height={144}
                      alt=""
                    />
                  </div>
                </figure>
                <figure
                  className={cx(
                    styles.flex1,
                    styles.boxOutline,
                    styles.verticalLogotypeBoxAlt
                  )}
                >
                  <div className={cx(styles.flex1, styles.flexFlow)}>
                    <div
                      className={cx(
                        styles.flex1,
                        styles.verticalLogotypeBoxAltInner
                      )}
                    >
                      <img
                        src={srcLogotypeVerticalMonoLineDark}
                        height={144}
                        alt=""
                      />
                    </div>
                    <div
                      className={cx(
                        styles.darkBlock,
                        styles.flex1,
                        styles.verticalLogotypeBoxAltInner
                      )}
                    >
                      <img
                        src={srcLogotypeVerticalMonoLineLight}
                        height={144}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={cx(styles.flex1, styles.flexFlow)}>
                    <div
                      className={cx(
                        styles.flex1,
                        styles.verticalLogotypeBoxAltInner
                      )}
                    >
                      <img
                        src={srcLogotypeVerticalMonoSolidDark}
                        height={144}
                        alt=""
                      />
                    </div>
                    <div
                      className={cx(
                        styles.darkBlock,
                        styles.flex1,
                        styles.verticalLogotypeBoxAltInner
                      )}
                    >
                      <img
                        src={srcLogotypeVerticalMonoSolidLight}
                        height={144}
                        alt=""
                      />
                    </div>
                  </div>
                </figure>
              </div>
              <h3>{t('brand-sec-logo-symbol-title')}</h3>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-logo-symbol-desc')}
              </Markdown>
              <figure
                className={cx(
                  styles.flex1,
                  styles.boxOutline,
                  styles.symbolBox
                )}
              >
                <div
                  className={cx(
                    styles.flex1,
                    styles.flexFlow,
                    styles.symbolFlow
                  )}
                >
                  <div className={styles.symbol}>
                    <img src={srcSymbolColor} width={64} alt="" />
                  </div>
                  <div className={styles.symbol}>
                    <img src={srcSymbolMonoSolidDark} width={64} alt="" />
                  </div>
                  <div className={styles.symbol}>
                    <img src={srcSymbolMonoLineDark} width={64} alt="" />
                  </div>
                </div>
                <div
                  className={cx(
                    styles.flex1,
                    styles.flexFlow,
                    styles.darkBlock,
                    styles.symbolFlow
                  )}
                >
                  <div className={styles.symbol}>
                    <img src={srcSymbolColor} width={64} alt="" />
                  </div>
                  <div className={styles.symbol}>
                    <img src={srcSymbolMonoSolidLight} width={64} alt="" />
                  </div>
                  <div className={styles.symbol}>
                    <img src={srcSymbolMonoLineLight} width={64} alt="" />
                  </div>
                </div>
              </figure>

              <h3>{t('brand-sec-logo-download-title')}</h3>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-logo-download-desc')}
              </Markdown>

              <div className={styles.actions}>
                <a
                  href="https://assets.smartpay.co/brand/smartpay-logo-2023-06.zip"
                  target="_blank"
                  className={styles.dlBtn}
                >
                  {t('brand-sec-logo-download-button')}
                  <img src={srcDownload} alt="" width={24} />
                </a>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="spacing">{t('brand-toc-spacing')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-spacing-desc')}
              </Markdown>
              <div className={cx(styles.flexFlow, styles.spacingBox)}>
                <figure>
                  <figcaption>{t('brand-type-horizontal')}</figcaption>
                  <GatsbyImage
                    alt=""
                    image={srcBrandClearSpace.childImageSharp.gatsbyImageData}
                  />
                </figure>
                <figure>
                  <figcaption>{t('brand-type-vertical')}</figcaption>
                  <GatsbyImage
                    alt=""
                    image={
                      srcBrandClearSpaceVertical.childImageSharp.gatsbyImageData
                    }
                  />
                </figure>
                <figure>
                  <figcaption>{t('brand-type-symbol')}</figcaption>
                  <GatsbyImage
                    alt=""
                    image={
                      srcBrandClearSpaceSymbol.childImageSharp.gatsbyImageData
                    }
                  />
                </figure>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="color-palette">{t('brand-toc-color-palette')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-color-palette-desc')}
              </Markdown>
              <ul className={styles.platte}>
                <li>
                  <div
                    className={cx(styles.colorBlock, styles.blackberry)}
                  ></div>
                  <h4>{t('brand-color-blackberry')}</h4>
                  <ul className={styles.colorCodes}>
                    <li>
                      <span className={styles.codeType}>HEX</span>
                      <span className={styles.codeValue}># 231C45</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>RGB</span>
                      <span className={styles.codeValue}>32 28 69</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>CMYK</span>
                      <span className={styles.codeValue}>95 95 40 75</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    className={cx(styles.colorBlock, styles.turquoise)}
                  ></div>
                  <h4>{t('brand-color-turquoise')}</h4>
                  <ul className={styles.colorCodes}>
                    <li>
                      <span className={styles.codeType}>HEX</span>
                      <span className={styles.codeValue}># 00D5E0</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>RGB</span>
                      <span className={styles.codeValue}>0 213 224</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>CMYK</span>
                      <span className={styles.codeValue}>92 0 12 0</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    className={cx(styles.colorBlock, styles.lightSeaGreen)}
                  ></div>
                  <h4>{t('brand-color-light-sea-green')}</h4>
                  <ul className={styles.colorCodes}>
                    <li>
                      <span className={styles.codeType}>HEX</span>
                      <span className={styles.codeValue}># 2DB2B2</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>RGB</span>
                      <span className={styles.codeValue}>45 178 178</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>CMYK</span>
                      <span className={styles.codeValue}>90 34 40 0</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    className={cx(styles.colorBlock, styles.veryLightBlue)}
                  ></div>
                  <h4>{t('brand-color-very-light-blue')}</h4>
                  <ul className={styles.colorCodes}>
                    <li>
                      <span className={styles.codeType}>HEX</span>
                      <span className={styles.codeValue}># 7280FF</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>RGB</span>
                      <span className={styles.codeValue}>114 128 255</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>CMYK</span>
                      <span className={styles.codeValue}>78 48 2 0</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    className={cx(styles.colorBlock, styles.wildStrawberry)}
                  ></div>
                  <h4>{t('brand-color-wild-strawberry')}</h4>
                  <ul className={styles.colorCodes}>
                    <li>
                      <span className={styles.codeType}>HEX</span>
                      <span className={styles.codeValue}># FF3F8F</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>RGB</span>
                      <span className={styles.codeValue}>255 63 143</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>CMYK</span>
                      <span className={styles.codeValue}>0 80 28 0</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className={cx(styles.colorBlock, styles.amber)}></div>
                  <h4>{t('brand-color-amber')}</h4>
                  <ul className={styles.colorCodes}>
                    <li>
                      <span className={styles.codeType}>HEX</span>
                      <span className={styles.codeValue}># FFC000</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>RGB</span>
                      <span className={styles.codeValue}>255 19 0</span>
                    </li>
                    <li>
                      <span className={styles.codeType}>CMYK</span>
                      <span className={styles.codeValue}>0 25 100 0</span>
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>{t('brand-sec-color-swatches-title')}</h3>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-color-swatches-desc')}
              </Markdown>

              <div className={styles.actions}>
                <a
                  href="https://assets.smartpay.co/brand/smartpay-colors-2023-06.zip"
                  target="_blank"
                  className={styles.dlBtn}
                >
                  {t('brand-sec-color-swatches-download')}
                  <img src={srcDownload} alt="" width={24} />
                </a>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="minimum-size">{t('brand-toc-minimum-size')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-color-minimum-size-desc')}
              </Markdown>
              <ol className={cx(styles.flexFlow, styles.minimumList)}>
                <li>
                  <figure>
                    <figcaption>{t('brand-type-digital')}</figcaption>
                    <div className={styles.digitalMinimumSamples}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandMinimumDigitalLogotype.childImageSharp
                            .gatsbyImageData
                        }
                        objectFit="contain"
                        className={cx(styles.boxOutline, styles.minimumBox)}
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandMinimumDigitalSymbol.childImageSharp
                            .gatsbyImageData
                        }
                        objectFit="contain"
                        className={cx(styles.boxOutline, styles.minimumBox)}
                      />
                    </div>
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-type-print')}</figcaption>
                    <div className={styles.printMinimumSamples}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandMinimumPrintLogotype.childImageSharp
                            .gatsbyImageData
                        }
                        className={cx(styles.boxOutline, styles.minimumBox)}
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandMinimumPrintSymbol.childImageSharp
                            .gatsbyImageData
                        }
                        className={cx(styles.boxOutline, styles.minimumBox)}
                      />
                    </div>
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-type-video')}</figcaption>
                    <div className={styles.videoMinimumSamples}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandMinimumVideoLogotype.childImageSharp
                            .gatsbyImageData
                        }
                        objectFit="contain"
                        className={cx(styles.boxOutline, styles.minimumBox)}
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandMinimumVideoSymbol.childImageSharp
                            .gatsbyImageData
                        }
                        objectFit="contain"
                        className={cx(styles.boxOutline, styles.minimumBox)}
                      />
                    </div>
                  </figure>
                </li>
              </ol>
            </section>
            <hr />
            <section>
              <h2 id="collaboration">{t('brand-toc-collaboration')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-color-collaboration-desc')}
              </Markdown>
              <div>
                <GatsbyImage
                  alt=""
                  image={
                    srcBrandPartnerCollaboration.childImageSharp.gatsbyImageData
                  }
                  className={cx(styles.collaborationTmpl)}
                />
              </div>
              <h3>{t('brand-sec-color-collaboration-tmpl-title')}</h3>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-color-collaboration-tmpl-desc')}
              </Markdown>

              <div className={styles.actions}>
                <a
                  href="https://assets.smartpay.co/brand/smartpay-collaboration-rgb-2023-06.zip"
                  target="_blank"
                  className={styles.dlBtn}
                >
                  RGB
                  <img src={srcDownload} alt="" width={24} />
                </a>
                <a
                  href="https://assets.smartpay.co/brand/smartpay-collaboration-cmyk-2023-06.zip"
                  target="_blank"
                  className={styles.dlBtn}
                >
                  CMYK
                  <img src={srcDownload} alt="" width={24} />
                </a>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="misuse">{t('brand-toc-misuse')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-misuse-desc')}
              </Markdown>
              <ul className={styles.misuseCases}>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-size')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageDistort.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-orientation')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageRotate.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-outline')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDonImageOutline.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-color')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageColor.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-effects')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageShadow.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-mirror')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageMirror.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-decorate')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontDecorate.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-in-text')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontUseInText.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-hide')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageHidden.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-rearrange')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontRearrange.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-border')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontOutlineEffect.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-break-spacing')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontBreakSpace.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-non-solid-bg')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontCutBackground.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-image-bg')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageBackground.childImageSharp
                          .gatsbyImageData
                      }
                      className={styles.caseSample}
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>{t('brand-misuse-contrast')}</figcaption>
                    <GatsbyImage
                      alt=""
                      image={
                        srcBrandMisuseDontImageContrast.childImageSharp
                          .gatsbyImageData
                      }
                      className={cx(styles.caseSample, styles.caseSampleAlt)}
                    />
                  </figure>
                </li>
              </ul>
            </section>
            <hr />
            <section>
              <h2 id="app-screenshots">{t('brand-toc-app-screenshots')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-app-screenshots-desc')}
              </Markdown>
              <div className={styles.appScreenshots}>
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotHome.childImageSharp.gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotPaymentMethods.childImageSharp
                      .gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotPayments.childImageSharp
                      .gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotPaymentDetail.childImageSharp
                      .gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotOrderSummary.childImageSharp
                      .gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotKycIntro.childImageSharp
                      .gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotKyc.childImageSharp.gatsbyImageData
                  }
                />
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  image={
                    srcBrandAppScreenshotKycPremium.childImageSharp
                      .gatsbyImageData
                  }
                />
              </div>
              <h3>{t('brand-sec-app-screenshots-download-title')}</h3>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-app-screenshots-download-desc')}
              </Markdown>

              <div className={styles.actions}>
                <a
                  href="https://assets.smartpay.co/brand/smartpay-app-screens-2023-11.zip"
                  target="_blank"
                  className={styles.dlBtn}
                >
                  {t('brand-sec-app-screenshots-download-button')}
                  <img src={srcDownload} alt="" width={24} />
                </a>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="banners">{t('brand-toc-banners')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-banners-desc')}
              </Markdown>
              <ul className={styles.banners}>
                <li>
                  <figure>
                    <figcaption>{t('brand-sec-banners-what-is')}</figcaption>
                    <div className={styles.bannerSample}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerLargeWhatIsSmartpay.childImageSharp
                            .gatsbyImageData
                        }
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerSquareWhatIsSmartpay.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </div>
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>
                      {t('brand-sec-banners-easy-to-use')}
                    </figcaption>
                    <div className={styles.bannerSample}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerLargeApp.childImageSharp.gatsbyImageData
                        }
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerSquareApp.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </div>
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>
                      {t('brand-sec-banners-how-it-works')}
                    </figcaption>
                    <div className={styles.bannerSample}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerLargeHowSmartpayWorks.childImageSharp
                            .gatsbyImageData
                        }
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerSquareHowSmartpayWorks.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </div>
                  </figure>
                </li>
                <li>
                  <figure>
                    <figcaption>
                      {t('brand-sec-banners-identity-verification')}
                    </figcaption>

                    <div className={styles.bannerSample}>
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerLargeKyc.childImageSharp.gatsbyImageData
                        }
                      />
                      <GatsbyImage
                        alt=""
                        image={
                          srcBrandBannerSquareKyc.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    </div>
                  </figure>
                </li>
              </ul>
              <h3>{t('brand-sec-banners-download-title')}</h3>

              <div className={styles.actions}>
                <a
                  href="https://assets.smartpay.co/brand/smartpay-banners-2023-08.zip"
                  target="_blank"
                  className={styles.dlBtn}
                >
                  {t('brand-sec-banners-download-button')}
                  <img src={srcDownload} alt="" width={24} />
                </a>
              </div>
            </section>
            <hr />
            <section>
              <h2 id="videos">{t('brand-toc-videos')}</h2>
              <Markdown options={{ forceBlock: true }}>
                {t('brand-sec-videos-desc')}
              </Markdown>
              <ul className={styles.videos}>
                <li>
                  <span className={styles.caption}>
                    {t('brand-sec-videos-how-to-use-smartpay')}
                  </span>
                  <GatsbyImage
                    alt=""
                    image={
                      srcBrandVideoHowToUseSmartpay.childImageSharp
                        .gatsbyImageData
                    }
                    className={styles.cover}
                  />
                  <CopyButton data="https://www.youtube.com/watch?v=cIkfDKeehXo">
                    {t('copy-video-url')}
                  </CopyButton>
                </li>
                <li>
                  <span className={styles.caption}>
                    {t('brand-sec-videos-how-to-use-bank-direct')}
                  </span>
                  <GatsbyImage
                    alt=""
                    image={
                      srcBrandVideoHowToUseBankDirect.childImageSharp
                        .gatsbyImageData
                    }
                    className={styles.cover}
                  />
                  <CopyButton data="https://www.youtube.com/watch?v=SWsLrp1Vafo">
                    {t('copy-video-url')}
                  </CopyButton>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BrandGuidelinesPage;
